import React, { useContext } from 'react';
import { PoseGroup } from 'react-pose';
import {
  ModalBlock,
  Modalfilm,
  ModalIcontBlock,
  ModalContent,
  ModalTextBlock,
  ModalButtonBlock,
  IconImg,
} from '../../style/ModalStyle';
import Button from '../Button';
import Fonts from '../Fonts';
import { AppContext } from '../../context/app';

import icon from '../../asset/all/passport.svg';

export default ({ active, handleHide, handleActive }) => {
  const { t } = useContext(AppContext);
  return (
    <PoseGroup>
      {active && (
        <ModalBlock key="ModalBlock">
          <Modalfilm onClick={handleHide} />
          <ModalContent>
            <ModalIcontBlock>
              <IconImg src={icon} style={{ width: 160 }} />
            </ModalIcontBlock>
            <ModalTextBlock>
              <Fonts
                text={t(['detailPage', 'dialogDiscount', 'titleShow'])}
                fontSize={18}
                fontWeight="bold"
                color="#333333"
              />
            </ModalTextBlock>
            <ModalButtonBlock>
              <Button onClick={handleHide}>
                <Fonts text={t(['cancel'])} fontSize={12} color="#fff" />
              </Button>
              <Button type="primary" onClick={handleActive}>
                <Fonts text={t(['confirm'])} fontSize={12} color="#fff" />
              </Button>
            </ModalButtonBlock>
          </ModalContent>
        </ModalBlock>
      )}
    </PoseGroup>
  );
};
