import get from 'lodash/get';

const pojectId = process.env.REACT_APP_LOCATION;
const pojectIdLS = process.env.REACT_APP_LOCATION_LS;

const defaultData = {
  touristDiscount: { en: 'Tourist Discount', cn: '优惠卡' },
  welcomeSnack: { en: 'Welcome Snack', cn: '欢迎小吃' },
  freeWifi: { title: { en: 'Free Wifi', cn: '免费无线网络' } },
  information: { en: 'INFORMATION', cn: '信息' },
  detailPage: {
    getDirection: { en: 'Get Direction', cn: 'Get Direction' },
    location: { en: 'Location', cn: '地址' },
    time: { en: 'Time', cn: '时间' },
    phone: { en: 'Phone', cn: '电话号码' },
    redeem: { en: 'Redeem', cn: '兑换' },
    fb: { en: 'FB', cn: 'FB' },
    ig: { en: 'IG', cn: 'IG' },
    website: { en: 'Website', cn: 'Website' },
    dialogDiscount: {
      titleShow: {
        en: 'Please show your passport at the shop.',
        cn: '请向商店出示您的护照',
      },
    },
    dialogSnack: {
      titleShow: { en: 'For staff to only', cn: '仅限员工' },
    },
    welcomeSnack: {
      getSnack: { en: 'Get Snack', cn: '获得小吃' },
      popErrorPass: {
        en: 'Authentication Failed<br/> Please try again.',
        cn: '验证失败，请重试',
      },
      touristNotice: {
        en: 'For staff to enter password',
        cn: '由员工输入密码',
      },
    },
  },
  cancel: { en: 'Cancel', cn: '取消' },
  confirm: { en: 'Confirm', cn: '确认' },
  register: {
    title: { en: 'Please Register', cn: 'PLEASE REGISTER' },
    passNo: { en: 'Passport No.', cn: '护照号码' },
    email: { en: 'Email', cn: '电子邮件' },
    country: { en: 'Country', cn: '国籍' },
    questCheck: {
      en: 'How did you know about us',
      cn: '您如何认识 Terminal21?',
    },
    FriendFamily: { en: 'Friend/Family', cn: '朋友/家庭' },
    Website: { en: 'Website', cn: '网站' },
    Facebook: { en: 'Facebook', cn: '脸书' },
    Instagram: { en: 'Instagram', cn: 'Instagram' },
    Weibo: { en: 'Weibo', cn: '微薄' },
    Magazine: { en: 'Magazine', cn: '杂志' },
    Influencer: { en: 'Influencer ...', cn: 'Influencer ...' },
    TiktokDouyin: { en: 'Tiktok / Douyin ...', cn: 'Tiktok / Douyin ...' },
    Other: { en: 'Other', cn: '其他' },
    HowdidyoutraveltoThailand: {
      en: 'How did you travel to Thailand',
      cn: '您如何来泰国旅行？',
    },
    GroupTour: { en: 'Group Tour', cn: '旅游团' },
    IndividualTourist: { en: 'Individual Tourist', cn: '个人游客' },
    register: { en: 'Register', cn: 'Register' },
    notice: {
      en: 'We will keep such information confidential for the security of your information.',
      cn: '我们将会为您的个人信息保密',
    },
  },
  success: { en: 'Success', cn: '成功' },
  ok: { en: 'ok', cn: 'ok' },
  touristDiscountText: {
    allCat: { en: 'All Category', cn: '类型' },
    recommend: { en: 'Recommend', cn: '建议' },
    mostCount: { en: 'Most Discount', cn: '最高折扣' },
  },
  wishlist: { en: 'My wishlist', cn: '我的收藏' },
};

const dataByProjectId = {
  terminal21_asok: {
    freeWifiContent: [
      {
        image: {
          en: require('../terminal21_asok/free-wifi/step1.png'),
          cn: require('../terminal21_asok/free-wifi/step1.png'),
        },
        title: { en: 'Step 1', cn: '第一步' },
        subTitle: {
          en: 'Login “@Terminal21_Free WiFi”',
          cn: '登入“@Terminal21_Free WiFi”',
        },
      },
      {
        image: {
          en: require('../terminal21_asok/free-wifi/step2.png'),
          cn: require('../terminal21_asok/free-wifi/step2.png'),
        },
        title: { en: 'Step 2', cn: '第二步' },
        subTitle: {
          en: 'Fill Passport ID and E-Mail Address',
          cn: '填写护照号码和电子邮件',
        },
      },
      {
        image: {
          en: require('../terminal21_asok/free-wifi/step3.png'),
          cn: require('../terminal21_asok/free-wifi/step3.png'),
        },
        title: { en: 'Step 3', cn: '第三步' },
        subTitle: { en: 'Enjoy your time!', cn: '购物愉快!' },
      },
    ],
    freeWifiDetail: {
      en: 'Use only at the TERMINAL 21',
      cn: '仅在 Terminal21使用',
    },
    notice: {
      en: 'You agree the Company to collect, use and disclose your personal data for purpose of marketing communication and our products & services development. Please read our Privacy Notice in <a href="http://www.terminal21.co.th" target="_blank">www.terminal21.co.th</a>',
      cn: '您同意本公司收集、使用和披露您的个人资料，用于营销传播以及产品和服务开发。请阅读隐私声明于 <a href="http://www.terminal21.co.th" target="_blank">www.terminal21.co.th</a>',
    },
  },
  terminal21_pattaya: {
    freeWifiContent: [
      {
        image: {
          en: require('../terminal21_pattaya/free-wifi/step1.jpg'),
          cn: require('../terminal21_pattaya/free-wifi/step1.jpg'),
        },
        title: { en: 'Step 1', cn: '第一步' },
        subTitle: {
          en: 'Login “.@Terminal21_Pty_FreeWiFi”',
          cn: '登入“.@Terminal21_Pty_FreeWiFi”',
        },
      },
      {
        image: {
          en: require('../terminal21_pattaya/free-wifi/step2.png'),
          cn: require('../terminal21_pattaya/free-wifi/step2.png'),
        },
        title: { en: 'Step 2', cn: '第二步' },
        subTitle: {
          en: 'Fill Passport ID and E-Mail Address',
          cn: '填写护照号码和电子邮件',
        },
      },
      {
        image: {
          en: require('../terminal21_pattaya/free-wifi/step3.png'),
          cn: require('../terminal21_pattaya/free-wifi/step3.png'),
        },
        title: { en: 'Step 3', cn: '第三步' },
        subTitle: { en: 'Enjoy your time!', cn: '购物愉快!' },
      },
    ],
  },
  terminal21_rama3: {
    freeWifiContent: [
      {
        image: {
          en: require('../terminal21_rama3/free-wifi/step1.jpg'),
          cn: require('../terminal21_rama3/free-wifi/step1.jpg'),
        },
        title: { en: 'Step 1', cn: '第一步' },
        subTitle: {
          en: 'Login “.@Terminal21_Pty_FreeWiFi”',
          cn: '登入“.@Terminal21_Pty_FreeWiFi”',
        },
      },
      {
        image: {
          en: require('../terminal21_rama3/free-wifi/step2.png'),
          cn: require('../terminal21_rama3/free-wifi/step2.png'),
        },
        title: { en: 'Step 2', cn: '第二步' },
        subTitle: {
          en: 'Fill Passport ID and E-Mail Address',
          cn: '填写护照号码和电子邮件',
        },
      },
      {
        image: {
          en: require('../terminal21_rama3/free-wifi/step3.png'),
          cn: require('../terminal21_rama3/free-wifi/step3.png'),
        },
        title: { en: 'Step 3', cn: '第三步' },
        subTitle: { en: 'Enjoy your time!', cn: '购物愉快!' },
      },
    ],
  },
  fashion: {
    freeWifiContent: [
      {
        image: {
          en: require('../fashion/free-wifi/step1.png'),
          cn: require('../fashion/free-wifi/step1.png'),
        },
        title: { en: 'Step 1', cn: '第一步' },
        subTitle: {
          en: 'Login “@Fashion-Promenade Wi-Fi”',
          cn: '登入“@Fashion-Promenade Wi-Fi”',
        },
      },
      {
        image: {
          en: require('../fashion/free-wifi/step2.png'),
          cn: require('../fashion/free-wifi/step2.png'),
        },
        title: { en: 'Step 2', cn: '第二步' },
        subTitle: {
          en: 'Fill Passport ID and E-Mail Address',
          cn: '填写护照号码和电子邮件',
        },
      },
      {
        image: {
          en: require('../fashion/free-wifi/step3.png'),
          cn: require('../fashion/free-wifi/step3.png'),
        },
        title: { en: 'Step 3', cn: '第三步' },
        subTitle: { en: 'Enjoy your time!', cn: '购物愉快!' },
      },
    ],
    freeWifiDetail: {
      en: 'Available @ Fashion-Promenade <br class="br-sm"/> Wi-Fi',
      cn: '登入“Fashion-Promenade Wi-Fi”',
    },
    notice: {
      en: 'We will keep such information confidential for the security of your information.',
      cn: '我们将会为您的个人信息保密',
    },
  },
};
export default {
  ...defaultData,
  ...get(dataByProjectId, [pojectId], {}),
  ...get(dataByProjectId, [pojectIdLS], {}),
};
