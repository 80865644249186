import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Icon } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';

import Axios from 'axios';
import ls from 'local-storage';
import { ContainerBlock } from '../style/global';
import Fonts from '../components/Fonts';
import theme from '../theme';
import { AppContext } from '../context/app';
import Button from '../components/Button';
import ShowPassportModal from '../components/ShowPassportModal';

import LocationIcon from '../asset/all/bitmap@3x.png';

export default () => {
  const {
    t, coupons, wishlistData, handleClickWishlist,
  } = useContext(
    AppContext,
  );
  const { id } = useParams();

  const [activeModal, setActiveModal] = useState(false);
  const [link, setLink] = useState(false);

  const showModal = () => {
    setActiveModal(true);
  };

  const handleHide = () => {
    setActiveModal(false);
  };

  const handleActive = () => {
    const token = ls.get(`token-${process.env.REACT_APP_LOCATION_LS}`);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Axios.post(
      `${process.env.REACT_APP_API_URL}api/v1/coupons/${id}/redeem`,
      {},
      config,
    );
    setActiveModal(false);
  };

  const coupon = _.find(coupons, (o) => o.id === _.toSafeInteger(id));
  // console.log({ coupon });

  const couponImage = _.get(coupon, ['image', 'full_file_path'], '');

  const getLocationLink = () => {
    Axios.post(
      `${process.env.REACT_APP_API_DIRECTORY_URL}redirect?response=json`,
      {
        // language: currentLang,
        language: 'en',
        project: _.get(coupon, ['location', 'id']),
        destination: _.get(coupon, ['shop_id']),
        options: {
          floor: 'slug',
        },
      },
    ).then((response) => {
      const url = _.get(response, ['data', 'link']);
      if (url) {
        setLink(url);
      }
    });
  };

  useEffect(() => {
    getLocationLink();
  }, [coupon]);
  console.log({ coupon });
  return (
    <ContainerBlock>
      <SliderContentBlock>
        <div style={{ position: 'relative' }}>
          <div style={{ width: 'calc(100% - 90px)' }}>
            <TextBlock style={{ lineHeight: '14px' }}>
              <Fonts
                text={t(['shop', 'title'], coupon)}
                fontSize={14}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'location'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.location')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'time'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.time')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'phone'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.contact.phone')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'fb'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.contact.facebook')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'ig'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.contact.instagram')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
            <TextBlock style={{ lineHeight: '12px' }}>
              <Fonts
                text={t(['detailPage', 'website'])}
                fontSize={12}
                fontWeight="bold"
                color={theme.textHeadColor}
              />
              {' '}
              <Fonts
                text={_.get(coupon, 'shop.contact.website')}
                fontSize={12}
                color={theme.textHeadColor}
              />
            </TextBlock>
          </div>
          {link && (
          <GetLocationIcon href={link}>
            <img src={LocationIcon} alt="" style={{ width: '86px' }} />
            <div>
              <Fonts
                text="GET DIRECTION"
                fontSize={12}
                fontWeight="bold"
                color="#fff"
                style={{ whiteSpace: 'nowrap' }}
              />
            </div>
          </GetLocationIcon>
          )}
        </div>
        <ContentBlock>
          <DetailBlock>
            <Icon
              onClick={handleClickWishlist(_.get(coupon, 'id'))}
              type="heart"
              theme={_.includes(wishlistData, _.get(coupon, 'id')) && 'filled'}
              style={{
                color: _.includes(wishlistData, _.get(coupon, 'id'))
                  ? '#d82759'
                  : '#333',
                position: 'absolute',
                top: 12,
                right: 12,
                fontSize: 14,
              }}
            />
            <TopDetailBlock>
              {_.get(coupon, ['shop', 'logo']) && (
                <ShopImage src={_.get(coupon, ['shop', 'logo'])} />
              )}
              <DetailTextBlock style={{ marginTop: 4 }}>
                <Fonts
                  text={t(['name'], coupon)}
                  fontSize={16}
                  fontWeight="bold"
                  color={theme.cardTextColorMenu}
                />
              </DetailTextBlock>
            </TopDetailBlock>
            <DetailTextBlock>
              <Fonts
                text={_.replace(t(['description'], coupon), /\n/g, '<br />')}
                fontSize={12}
                color={theme.cardTextColorMenu}
              />
            </DetailTextBlock>
          </DetailBlock>
          {couponImage && (
            <CoupondImage
              style={{
                backgroundImage: `url(${couponImage})`,
              }}
            />
          )}

          <RemarkBlock>
            <Fonts
              text={t(['remark'], coupon) && `* ${t(['remark'], coupon)}`}
              fontSize={12}
              color={theme.cardTextColorMenu}
            />
          </RemarkBlock>
          <Row type="flex" justify="center">
            <Col>
              <Button
                style={{ marginTop: 24, marginBottom: 24 }}
                type="primary"
                onClick={showModal}
              >
                <Fonts
                  text={t(['detailPage', 'redeem'])}
                  fontSize={12}
                  color="#fff"
                />
              </Button>
            </Col>
          </Row>
        </ContentBlock>
      </SliderContentBlock>
      <ShowPassportModal
        active={activeModal}
        // handleHide={() => {}}
        // handleActive={() => {}}
        handleHide={handleHide}
        handleActive={handleActive}
      />
    </ContainerBlock>
  );
};

const SliderContentBlock = styled.div`
  width: 100%;
  min-height: 423px;
  padding: 16px 35px 0;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 320px) {
    padding: 8px 16px 0;
  }
`;

const TextBlock = styled.p`
  padding-left: 16px;
  margin-bottom: 0;
`;

const DetailTextBlock = styled(TextBlock)`
  padding-left: 0;
`;

const ContentBlock = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: inset 0 4px 0 0 #fff;
  background: ${(props) => props.theme.detailBg};
  margin-top: 40px;
  position: relative;
`;

const CoupondImage = styled.div`
  width: 100%;
  padding-bottom: calc(100% * 212 / 308);
  background-size: cover;
  background-position: top center;
`;

const TopDetailBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 4px;
`;

const ShopImage = styled.img`
  width: 96px;
  height: 96px;
  flex: 0 0 auto;
  margin-right: 14px;
`;

const DetailBlock = styled.div`
  width: 100%;
  padding: 20px;
`;

const RemarkBlock = styled(DetailTextBlock)`
  padding: 4px 20px 0;
`;

const GetLocationIcon = styled.a`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
