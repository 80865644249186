import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import styled, { ThemeProvider } from 'styled-components';
import includes from 'lodash/includes';
import RegisterScreen from '../screens/RegisterScreen';
import PrivateRoute from './PrivateRoute';
import CouponScreen from '../screens/CouponScreen';
import theme from '../theme';
import HomeScreen from '../screens/HomeScreen';
import FreeSnackScreen from '../screens/FreeSnackScreen';
import FreeWifiScreen from '../screens/FreeWifiScreen';
import HeaderBar from '../components/HeaderBar';
import { AppProvider } from '../context/app';
import DetailScreen from '../screens/DetailScreen';

export default () => {
  const { lang } = useParams();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const elScroll = document.getElementById('scroll-block');
    if (elScroll) {
      elScroll.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <MainContainer>
          <ScrollBlock id="scroll-block">
            <HeaderBar />
            <PoseGroup>
              <RouteContainer key={location.pathname}>
                <Switch location={location}>
                  <Route exact path={`/${lang}`}>
                    <HomeScreen />
                  </Route>
                  <Route exact path={`/${lang}/register`}>
                    <RegisterScreen />
                  </Route>
                  <PrivateRoute exact path={`/${lang}/detail/:id`}>
                    <DetailScreen />
                  </PrivateRoute>
                  <PrivateRoute exact path={`/${lang}/coupon/:type?`}>
                    <CouponScreen />
                  </PrivateRoute>
                  <PrivateRoute exact path={`/${lang}/free-snack`}>
                    <FreeSnackScreen />
                  </PrivateRoute>
                  <PrivateRoute exact path={`/${lang}/free-wifi`}>
                    <FreeWifiScreen />
                  </PrivateRoute>
                </Switch>
              </RouteContainer>
            </PoseGroup>
            <CompanyLogoBlock>
              <CompanyLogo src={theme.companyLogo} />
            </CompanyLogoBlock>
          </ScrollBlock>
        </MainContainer>
      </AppProvider>
    </ThemeProvider>
  );
};

/* *********************** Route Container *********************** */
const RouteContainerAnimate = posed.div({
  enter: { opacity: 1, beforeChildren: true },
  exit: { opacity: 0 },
});
const RouteContainer = styled(RouteContainerAnimate)`
  width: 100%;
`;
/* ********************* End Route Container ********************* */

const MainContainer = styled.div`
  background: url(${theme.appBackground});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 60px;
  overflow: hidden;
`;

const ScrollBlock = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CompanyLogoBlock = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 14px;

  margin-top: 24px;
`;

const CompanyLogo = styled.img`
  height: ${includes(process.env.REACT_APP_LOCATION, 'terminal')
    ? '18px'
    : '21.6px'};
`;
