import React, { useContext } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import _ from 'lodash';
import posed from 'react-pose';
import { AppContext } from '../context/app';
import { Flex, ContainerBlock } from '../style/global';

import m1 from '../asset/all/m1.png';
import m2 from '../asset/all/m2.png';
import m3 from '../asset/all/m3.png';
import Fonts from '../components/Fonts';
import theme from '../theme';

export default (props) => {
  const { homeBanner, t, changeUrl } = useContext(AppContext);

  const settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <HomeContainer>
      <SliderContainer>
        <Slider {...settings}>
          {_.map(homeBanner, (o, index) => (
            <ImageBanner key={`banner-${index}`} img={o} />
          ))}
        </Slider>
      </SliderContainer>
      <MenuBlock onClick={changeUrl('/coupon')}>
        <MenuItem border>
          <MenuItemImage src={m1} alt="" />
        </MenuItem>
        <FontBlock>
          <Fonts
            text={t('touristDiscount')}
            fontSize={18}
            fontWeight="bold"
            color={theme.textColorMenu}
          />
        </FontBlock>
      </MenuBlock>
      <MenuBlock onClick={changeUrl('/free-snack')}>
        <MenuItem border>
          <MenuItemImage src={m2} alt="" />
        </MenuItem>
        <FontBlock>
          <Fonts
            text={t('welcomeSnack')}
            fontSize={18}
            fontWeight="bold"
            color={theme.textColorMenu}
          />
        </FontBlock>
      </MenuBlock>
      <MenuBlock onClick={changeUrl('/free-wifi')}>
        <MenuItem border>
          <MenuItemImage src={m3} alt="" />
        </MenuItem>
        <FontBlock>
          <Fonts
            text={t(['freeWifi', 'title'])}
            fontSize={18}
            fontWeight="bold"
            color={theme.textColorMenu}
          />
          <Fonts
            text={t('freeWifiDetail')}
            fontSize={12}
            color={theme.textColorMenu}
          />
        </FontBlock>
      </MenuBlock>
    </HomeContainer>
  );
};

const HomeContainer = styled(ContainerBlock)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const SliderContainerBlock = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const SliderContainer = styled(SliderContainerBlock)`
  width: 100%;
  padding: 8px 33px 20px;
  .img-size {
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    padding: 10px 33px;
  }
`;

const MenuBlockAnimate = posed.div({
  pressable: true,
  init: { opacity: 1 },
  press: { opacity: 0.5 },
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: '100%',
    opacity: 0,
  },
});

const MenuBlock = styled(MenuBlockAnimate)`
  margin: 10px 0;
  width: calc(100% - 40px);
  opacity: 0.8;
  border-radius: 100px;
  background: ${(props) => props.theme.homeMenuBackground};
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  @media screen and (max-width: 320px) {
    margin: 5px 0;
  }
`;

const MenuItem = styled.div`
  width: 80px;
  height: 80px;
  margin: 16px;

  ${(props) => props.theme.homeMenuIconStye}

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 auto;

  @media screen and (max-width: 320px) {
    width: 50px;
    height: 50px;
  }
`;

const MenuItemImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  pointer-events: none;

  @media screen and (max-width: 320px) {
    width: 30px;
    height: 30px;
  }
`;

const FontBlock = styled(Flex)`
  flex-direction: column;
`;

const ImageBanner = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: calc(100% * 1192 / 2934);
  background: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
`;
