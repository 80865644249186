import React, { useContext } from 'react';
import upperFirst from 'lodash/upperFirst';
import { AppContext } from '../../context/app';

export default ({
  fontSize = 8,
  fontWeight = '',
  text = '',
  color = '#000',
  style = {},
}) => {
  const { currentLang, appWidth } = useContext(AppContext);

  let fontFamily = `Helvetica${upperFirst(fontWeight)}`;
  let fontSizeIn = '1em';
  if (currentLang === 'cn') {
    fontFamily = `NotoSansSC${upperFirst(fontWeight)}, Helvetica${upperFirst(fontWeight)}`;
    fontSizeIn = '0.9em';
  }

  const useFontSize = appWidth <= 320 ? fontSize - 2 : fontSize;

  return (
    <span style={{ fontSize: useFontSize, color }}>
      <span style={{ fontSize: fontSizeIn, fontFamily, ...style }} dangerouslySetInnerHTML={{ __html: text }} />
    </span>
  );
};
