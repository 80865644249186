import styled, { createGlobalStyle } from 'styled-components';
import posed from 'react-pose';

import Helvetica from '../asset/fonts/helvetica/helr45w_2.woff';
import HelveticaBold from '../asset/fonts/helvetica/helr65w_2.woff';

import NotoSansSC from '../asset/fonts/NotoSansSC/NotoSansSC-Medium-Alphabetic.woff';
import NotoSansSCBold from '../asset/fonts/NotoSansSC/NotoSansSC-Bold-Alphabetic.woff';
import theme from '../theme';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Helvetica';
    src: url(${Helvetica}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'HelveticaBold';
    src: url(${HelveticaBold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NotoSansSC';
    src: url(${NotoSansSC}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NotoSansSCBold';
    src: url(${NotoSansSCBold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  #root, .App {
    width: 100%;
    height: 100%;
  }

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
    .ant-spin-container{
      width: 100%;
      height: 100%;
    }
  }



  .slick-arrow.slick-prev {
    &::before {
      content: "";
      display: none;
    }
  }
  .slick-arrow.slick-next {
    &::before {
      content: "";
      display: none;
    }
  }
  .slick-dots {
    bottom: 0;
    li {
      button:before {
        opacity: 1;
        color: ${theme.sliderBulletColor};
        font-size: 12px;
      }
      &.slick-active {
        button:before {
          opacity: 1;
          color: ${theme.sliderBulletColorActive};
          font-size: 16px;
        }
      }
    }
  }

  .br-sm{
    display: none;
    @media screen and (max-width: 320px) {
      display: block;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 34px;
`;

export const ButtonOnPressAnimate = posed.div({
  pressable: true,
  init: { opacity: 1 },
  press: { opacity: 0.5 },
});

export const Flex = styled.div`
  display: flex;
`;

export const ContainerAnimate = posed.div({
  enter: { opacity: 1, staggerChildren: 50 },
  exit: { opacity: 0, staggerChildren: 50, staggerDirection: -1 },
});

export const ContainerBlock = styled(ContainerAnimate)`
  height: 100%;
`;
