import React, { useContext } from 'react';
import { PoseGroup } from 'react-pose';
import {
  ModalBlock,
  Modalfilm,
  ModalIcontBlock,
  ModalContent,
  ModalTextBlock,
  ModalButtonBlock,
  IconImg,
} from '../../style/ModalStyle';
import Button from '../Button';
import Fonts from '../Fonts';
import { AppContext } from '../../context/app';

import icon from '../../asset/all/staff.png';
import theme from '../../theme';

export default ({ active, handlHide, handlShowPasscode }) => {
  const { t } = useContext(AppContext);
  return (
    <PoseGroup>
      {active && (
        <ModalBlock key="ModalBlock">
          <Modalfilm
            onClick={() => {
              handlHide();
            }}
          />
          <ModalContent>
            <ModalIcontBlock>
              <IconImg src={theme.iconStaff} style={{ width: 93 }} />
            </ModalIcontBlock>
            <ModalTextBlock>
              <Fonts
                text={t(['detailPage', 'dialogSnack', 'titleShow'])}
                fontSize={18}
                fontWeight="bold"
                color="#333333"
              />
            </ModalTextBlock>
            <ModalButtonBlock>
              <Button
                onClick={() => {
                  handlHide();
                }}
              >
                <Fonts text={t(['cancel'])} fontSize={12} color="#fff" />
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handlHide();
                  handlShowPasscode();
                }}
              >
                <Fonts text={t(['confirm'])} fontSize={12} color="#fff" />
              </Button>
            </ModalButtonBlock>
          </ModalContent>
        </ModalBlock>
      )}
    </PoseGroup>
  );
};
