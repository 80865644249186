import React from 'react';
import styled from 'styled-components';
import { ButtonOnPressAnimate } from '../../style/global';

export default ({
  children, type, onClick, style,
}) => (
  <ButtonStyle style={style} onClick={onClick} type={type}>
    {children}
  </ButtonStyle>
);

const ButtonStyle = styled(ButtonOnPressAnimate)`
  border-radius: 4px;
  padding: 7px 13px;
  margin: 8px;
  box-shadow: inset 0 2px 0 0 #fff;
  color: #fff;
  background-color: ${(props) => (props.type === 'primary' ? props.theme.btnPrimary : props.theme.btnSecond)};
`;
