import React from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import ls from 'local-storage';

const PrivateRoute = ({ children, ...rest }) => {
  const token = ls.get(`token-${process.env.REACT_APP_LOCATION_LS}`);
  const { lang } = useParams();
  return (
    <Route
      {...rest}
      render={() => (token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: `/${lang}/register`,
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
