import React, { useContext, useState } from 'react';
import {
  Input, Icon, Checkbox, Select,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import { ContainerBlock } from '../style/global';
import { AppContext } from '../context/app';
import theme from '../theme';
import Fonts from '../components/Fonts';

const { Option } = Select;

export default () => {
  const {
    t,
    categories,
    coupons,
    currentLang,
    wishlistData,
    handleClickWishlist,
  } = useContext(AppContext);

  const { type } = useParams();
  const history = useHistory();

  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState('');
  const [selectCategory, setSelectCategory] = useState('all');
  const [recommend, setRecommend] = useState(false);
  const [most, setMost] = useState(false);

  const handleChangeCategory = (value) => {
    setSelectCategory(value);
  };

  const handleRecommendChange = (e) => {
    setRecommend(_.get(e, 'target.checked', false));
  };

  const handleDiscountChange = (e) => {
    setMost(_.get(e, 'target.checked', false));
  };

  const handleClickSearch = () => {
    if (searchActive) {
      setSearch('');
    }
    setSearchActive(!searchActive);
  };

  const handleEnterSearch = (e) => {
    setSearch(_.get(e, 'target.value', ''));
  };

  const handleClickCoupon = (id) => () => {
    history.push(`/${currentLang}/detail/${id}`);
  };

  const filterData = _.chain(coupons)
    .filter((o) => {
      const startDate = moment(o.publish_start_time).format('YYYY-MM-DD');
      const stopDate = moment(o.publish_end_time).format('YYYY-MM-DD');

      const searchValue = !searchActive
        || _.includes(
          _.toLower(_.get(o, ['name', currentLang], '')),
          _.toLower(search),
        )
        || _.some(_.get(o, ['tags'], ''), (tag) => _.includes(_.toLower(tag), _.toLower(search)));
      return moment().isBetween(startDate, stopDate, null, '[]') && searchValue;
    })
    .filter((o) => {
      if (type === 'mywishlist') {
        return _.includes(wishlistData, o.id);
      }
      if (recommend) {
        return o.recommended;
      }
      if (selectCategory === 'all') {
        return true;
      }
      return _.includes(o.category_ids, selectCategory);
    })
    .orderBy(
      [
        (o) => {
          if (most) {
            return _.toNumber(o.discount);
          }
          return o.sort;
        },
      ],
      [most ? 'desc' : 'asc'],
    )
    .value();
  // console.log({ filterData });
  return (
    <ContainerBlock>
      <CouponBlock>
        {type !== 'mywishlist' && (
          <FilterBlock>
            <FilterBlockTop>
              <PoseGroup>
                {searchActive ? (
                  <InputSearchBlock key="serch">
                    <Input onChange={handleEnterSearch} />
                  </InputSearchBlock>
                ) : (
                  <InputSearchBlock key="select">
                    <Select
                      key="category"
                      defaultValue="all"
                      style={{ width: '100%' }}
                      onChange={handleChangeCategory}
                    >
                      <Option value="all" key="all">
                        <Fonts
                          text={t(['touristDiscountText', 'allCat'])}
                          fontSize={12}
                          fontWeight="bold"
                          color="#333333"
                        />
                      </Option>
                      {_.map(categories, (category) => (
                        <Option
                          value={_.get(category, ['id'])}
                          key={_.get(category, ['id'])}
                        >
                          <Fonts
                            text={t(['name'], category)}
                            fontSize={12}
                            fontWeight="bold"
                            color="#333333"
                          />
                        </Option>
                      ))}
                    </Select>
                  </InputSearchBlock>
                )}
              </PoseGroup>
              <SearchButton onClick={handleClickSearch}>
                <PoseGroup>
                  {searchActive ? (
                    <IconBlock key="close">
                      <Icon style={{ fontSize: 15 }} type="close" />
                    </IconBlock>
                  ) : (
                    <IconBlock key="search">
                      <Icon style={{ fontSize: 15 }} type="search" />
                    </IconBlock>
                  )}
                </PoseGroup>
              </SearchButton>
            </FilterBlockTop>
            <FilterBlockBottom>
              <Checkbox onChange={handleRecommendChange}>
                <Fonts
                  text={t(['touristDiscountText', 'recommend'])}
                  fontSize={12}
                  fontWeight="bold"
                  color={theme.btnFIlterColor}
                />
              </Checkbox>
              <Checkbox onChange={handleDiscountChange}>
                <Fonts
                  text={t(['touristDiscountText', 'mostCount'])}
                  fontSize={12}
                  fontWeight="bold"
                  color={theme.btnFIlterColor}
                />
              </Checkbox>
            </FilterBlockBottom>
          </FilterBlock>
        )}
        <ContentBlock type={type}>
          {_.map(filterData, (coupon) => {
            // console.log({ coupon });
            let couponLocation = null;
            if (theme.fashionIcon && _.get(coupon, 'location_id') === 1) {
              couponLocation = <LocationImg src={theme.fashionIcon} />;
            }
            if (theme.promenadeIcon && _.get(coupon, 'location_id') === 2) {
              couponLocation = <LocationImg src={theme.promenadeIcon} />;
            }
            return (
              <List key={coupon.id}>
                <ListLogo onClick={handleClickCoupon(coupon.id)}>
                  <LogoImg src={_.get(coupon, ['shop', 'logo'])} />
                </ListLogo>
                <ListContent onClick={handleClickCoupon(coupon.id)}>
                  <div style={{ width: '100%' }}>
                    <TitleBlock>
                      <Fonts
                        text={t(['name'], coupon)}
                        fontSize={16}
                        fontWeight="bold"
                        color={theme.btnFIlterColor}
                      />
                    </TitleBlock>
                    <DetailBlock>
                      <Fonts
                        text={t(['description'], coupon)}
                        fontSize={12}
                        color={theme.btnFIlterColor}
                      />
                    </DetailBlock>
                  </div>
                  {couponLocation}
                </ListContent>
                <Icon
                  onClick={handleClickWishlist(coupon.id)}
                  type="heart"
                  theme={_.includes(wishlistData, coupon.id) && 'filled'}
                  style={{
                    color: _.includes(wishlistData, coupon.id)
                      ? '#d82759'
                      : theme.heartBorder,
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    fontSize: 14,
                  }}
                />
              </List>
            );
          })}
        </ContentBlock>
      </CouponBlock>
    </ContainerBlock>
  );
};

const CouponBlock = styled.div`
  width: 100%;
  padding: 0 30px;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    line-height: 1em;
    width: 118px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.btnFIlterBG};
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-checkbox + span {
      padding-right: 4px;
      padding-left: 4px;
    }

    .ant-checkbox {
      top: 0;
      &:after {
        border-radius: 50%;
      }
      .ant-checkbox-inner {
        border: ${(props) => props.theme.checkboxBorder};
        border-radius: 50%;
        background: ${(props) => props.theme.checkboxBg || '#fff'};
        ${(props) => props.theme.customCheckboxNormal || ''}
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #25ae88;
          border: 1px solid #25ae88;
          ${(props) => props.theme.customCheckboxActive || ''}
        }

        &:after {
          border: 1px solid #25ae88;
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    padding: 0 16px;
  }
`;

const FilterBlock = styled.div`
  width: 100%;
  padding: 16px 24px;
`;

const FilterBlockTop = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const SearchButtonAnimate = posed.div({
  pressable: true,
  init: { opacity: 1 },
  press: { opacity: 0.5 },
});
const SearchButton = styled(SearchButtonAnimate)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.btnFIlterBG};
  color: ${(props) => props.theme.btnFIlterColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  flex: 0 0 auto;
`;

const InputSearchBlock = posed.div({
  enter: {
    width: 'calc(100% - 36px)',
    // height: 32
    y: 0,
    opacity: 1,
  },
  exit: {
    width: 'calc(100% - 36px)',
    // height: 0
    y: 10,
    opacity: 0,
  },
});

const IconBlock = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const FilterBlockBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContentBlock = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

const List = styled.div`
  width: 100%;
  margin-bottom: 8px;

  display: flex;

  position: relative;
`;

const ListLogo = styled.div`
  flex: 0 0 auto;
  width: 96px;
  height: 96px;
  object-fit: contain;
  background: ${(props) => props.theme.listLogo};
  background-size: contain;
  background-repeat: no-repeat;
`;
const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ListContent = styled.div`
  width: 212px;
  height: 96px;
  background: ${(props) => props.theme.listContent};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex: 1 1 auto;
  padding: 10px 24px 10px 16px;

  line-height: 1;

  flex-direction: row;
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
`;

const TitleBlock = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
  color: ${(props) => props.theme.btnFIlterColor};
  flex: 1 0 100%;
`;

const DetailBlock = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  opacity: 0.8;
  color: ${(props) => props.theme.btnFIlterColor};
  flex: 1 0 100%;
`;

const LocationImg = styled.img`
  height: 11px;
  align-self: flex-end;
  flex: 0 0 auto;
  margin-top: 8px;
`;
