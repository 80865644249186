/* eslint-disable camelcase */
import React, { useContext } from 'react';
import {
  Form, Input, Select, Checkbox, Row,
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import Axios from 'axios';
import ls from 'local-storage';
import { ContainerBlock } from '../style/global';
import {
  CardBlock,
  TitleCardBlock,
  ImageTitleCardBlock,
} from '../style/CardStyle';
import Fonts from '../components/Fonts';
import icon from '../asset/all/register.svg';
import theme from '../theme';
import { AppContext } from '../context/app';
import country from '../asset/country';
import Button from '../components/Button';

const { Option } = Select;

export default Form.create()(
  ({ form: { getFieldDecorator, validateFields, getFieldValue } }) => {
    const history = useHistory();
    const { t, setLoading } = useContext(AppContext);
    const handleSubmit = (e) => {
      e.preventDefault();
      validateFields(async (err, values) => {
        if (!err && _.get(values, 'agreement')) {
          setLoading(true);
          const howDidYourKnowAboutUs = _.get(
            values,
            'how_did_your_know_about_us',
          );

          let how_did_your_know_about_us = howDidYourKnowAboutUs;

          if (howDidYourKnowAboutUs === 'Other') {
            how_did_your_know_about_us = _.get(values, 'other');
          } else if (howDidYourKnowAboutUs === 'Influencer ...') {
            how_did_your_know_about_us = `Influencer ... : ${_.get(
              values,
              'other',
            )}`;
          } else if (howDidYourKnowAboutUs === 'Tiktok / Douyin ...') {
            how_did_your_know_about_us = `Tiktok / Douyin ... : ${_.get(
              values,
              'other',
            )}`;
          }

          await Axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/auth/register`,
            {
              passport_no: _.get(values, 'passport_no'),
              email: _.get(values, 'email'),
              country: _.get(values, 'country'),
              how_did_your_know_about_us,
              how_did_you_travel_to_thailand: _.get(
                values,
                'how_did_you_travel_to_thailand',
              ),
            },
          )
            .then((response) => {
              ls.set(
                `token-${process.env.REACT_APP_LOCATION_LS}`,
                _.get(response, ['data', 'data', 'token']),
              );
              history.goBack();
            })
            .catch((error) => {});
          setLoading(false);
        }
      });
    };
    return (
      <ContainerBlock>
        <CardBlock>
          <TitleCardBlock>
            <Fonts
              text={t(['register', 'title'])}
              fontSize={18}
              fontWeight="bold"
              color={theme.cardTextColorMenu}
            />
            <ImageTitleCardBlock style={{ height: 37 }} src={icon} />
          </TitleCardBlock>
          <FormBlock>
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
              onSubmit={handleSubmit}
            >
              <Form.Item
                label={(
                  <Fonts
                    text={t(['register', 'passNo'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                )}
              >
                {getFieldDecorator('passport_no', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Passport No.!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item
                label={(
                  <Fonts
                    text={t(['register', 'email'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                )}
              >
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item
                label={(
                  <Fonts
                    text={t(['register', 'country'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                )}
              >
                {getFieldDecorator('country', {
                  rules: [
                    { required: true, message: 'Please input your Country' },
                  ],
                })(
                  <Select
                    showSearch
                    getPopupContainer={(node) => node.parentElement}
                  >
                    {_.map(_.sortBy(country, ['name']), (o) => (
                      <Option value={o.name}>{o.name}</Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item
                label={(
                  <Fonts
                    text={t(['register', 'questCheck'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                )}
              >
                {getFieldDecorator('how_did_your_know_about_us', {
                  rules: [{ required: true, message: 'Please select' }],
                })(
                  <Select>
                    <Option value="Friend/Family">
                      <Fonts
                        text={t(['register', 'FriendFamily'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Website">
                      <Fonts
                        text={t(['register', 'Website'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Facebook">
                      <Fonts
                        text={t(['register', 'Facebook'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Instagram">
                      <Fonts
                        text={t(['register', 'Instagram'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Weibo">
                      <Fonts
                        text={t(['register', 'Weibo'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    {/* <Option value="Magazine">
                      <Fonts
                        text={t(['register', 'Magazine'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option> */}
                    <Option value="Influencer ...">
                      <Fonts
                        text={t(['register', 'Influencer'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Tiktok / Douyin ...">
                      <Fonts
                        text={t(['register', 'TiktokDouyin'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Other">
                      <Fonts
                        text={t(['register', 'Other'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                  </Select>,
                )}
              </Form.Item>
              {(getFieldValue('how_did_your_know_about_us') === 'Other'
                || getFieldValue('how_did_your_know_about_us')
                  === 'Influencer ...'
                || getFieldValue('how_did_your_know_about_us')
                  === 'Tiktok / Douyin ...') && (
                  <Form.Item>{getFieldDecorator('other')(<Input />)}</Form.Item>
              )}
              <Form.Item
                label={(
                  <Fonts
                    text={t(['register', 'HowdidyoutraveltoThailand'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                )}
              >
                {getFieldDecorator('how_did_you_travel_to_thailand', {
                  rules: [{ required: true, message: 'Please select' }],
                })(
                  <Select>
                    <Option value="Group Tour">
                      <Fonts
                        text={t(['register', 'GroupTour'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                    <Option value="Individual Tourist">
                      <Fonts
                        text={t(['register', 'IndividualTourist'])}
                        fontSize={12}
                        fontWeight="bold"
                        color={theme.cardTextColorMenu}
                      />
                    </Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('agreement', {
                  rules: [{ required: true, message: 'Please accept' }],
                  valuePropName: 'checked',
                })(
                  <Checkbox style={{ lineHeight: '1em' }}>
                    <Fonts
                      // text={t(['register', 'notice'])}
                      text={t(['notice'])}
                      fontSize={12}
                      fontWeight="bold"
                      color={theme.cardTextColorMenu}
                    />
                  </Checkbox>,
                )}
              </Form.Item>
              <Form.Item>
                <Row type="flex" justify="center" style={{ lineHeight: '1em' }}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    htmlType="submit"
                  >
                    <Fonts
                      text={t(['register', 'register'])}
                      fontSize={12}
                      color="#fff"
                    />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </FormBlock>
        </CardBlock>
      </ContainerBlock>
    );
  },
);

const FormBlock = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  overflow: scroll;
`;
