import get from 'lodash/get';

const pojectId = process.env.REACT_APP_LOCATION;

const theme = {
  terminal21_asok: {
    appBackground: require('./asset/terminal21_asok/bg.jpg'),
    iconStaff: require('./asset/terminal21_asok/staff.png'),

    homeMenuBackground: '#1e4872',
    homeMenuIconStye: `box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5),
    rgb(216, 216, 216) 0px 3px 3px -2px inset;
  background-color: rgba(216, 216, 216, 0.2);`,

    cardBg: '#fff',
    headButtonBackgroundColor: `url('${require('./asset/terminal21_asok/btn.svg')}')`,
    companyLogo: require('./asset/terminal21_asok/terminal-21-logo-png-5@3x.png'),
    fg: 'palevioletred',
    bg: 'white',
    textColorMenu: '#fff',
    cardTextColorMenu: '#333333',
    sliderBulletColorActive: '#d72759',
    sliderBulletColor: '#d8d8d8',
    textHeadColor: '#fff',
    btnPrimary: '#333333',
    btnSecond: '#24a9e0',
    btnFIlterBG: '#18355d',
    btnFIlterColor: '#fff',
    listLogo: `url('${require('./asset/terminal21_asok/list-logo.png')}')`,
    listContent: `url('${require('./asset/terminal21_asok/list-content.png')}')`,
    checkboxBorder: '1px solid #fff',
    detailBg: 'radial-gradient(circle at 50% 0%, #fff, #e3eaed 47%)',
    heartBorder: '#fff',
  },
  fashion: {
    appBackground: require('./asset/fashion/bg.jpg'),
    iconStaff: require('./asset/fashion/staff.png'),
    homeMenuBackground: `url('${require('./asset/fashion/home-menu.png')}') no-repeat center center / 100% 100%`,
    homeMenuIconStye: '',
    cardBg: `url('${require('./asset/fashion/card-bg.png')}') no-repeat center center / 100% 100%`,
    headButtonBackgroundColor: `url('${require('./asset/fashion/btn.svg')}')`,
    companyLogo: require('./asset/fashion/company-logo.png'),
    // fg: 'palevioletred',
    // bg: 'white',
    textColorMenu: '#333333',
    cardTextColorMenu: '#333333',
    sliderBulletColorActive: '#2296f3',
    sliderBulletColor: '#d8d8d8',
    // textHeadColor: '#fff',
    btnPrimary: '#333333',
    btnSecond: '#24a9e0',
    btnFIlterBG: '#fff',
    btnFIlterColor: '#333333',
    listLogo: `url('${require('./asset/fashion/list-logo.png')}')`,
    listContent: `url('${require('./asset/fashion/list-content.png')}')`,
    checkboxBorder: 'none',
    checkboxBg: '#cccccc',
    customCheckboxNormal: 'width: 12px; height: 12px; margin: 2px;',
    customCheckboxActive: 'width: 16px; height: 16px; margin: 0px;',
    detailBg: `url('${require('./asset/fashion/card-bg-2.png')}') no-repeat center center / 100% 100%`,
    heartBorder: '#000',
    fashionIcon: require('./asset/fashion/fashion.png'),
    promenadeIcon: require('./asset/fashion/promenade.png'),
  },
};

export default get(theme, [pojectId], {});
