import React, { useContext } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Slider from 'react-slick';

import { ContainerBlock } from '../style/global';
import theme from '../theme';
import Fonts from '../components/Fonts';
import { AppContext } from '../context/app';

import wifiIcon from '../asset/all/wifi.png';
import nextIcon from '../asset/all/next.svg';
import prevIcon from '../asset/all/prev.svg';
import {
  CardBlock,
  TitleCardBlock,
  ImageTitleCardBlock,
} from '../style/CardStyle';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 12,
        height: 22,
      }}
      onClick={onClick}
    >
      <img src={prevIcon} alt="" />
    </div>
  );
};
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 12,
        height: 22,
      }}
      onClick={onClick}
    >
      <img src={nextIcon} alt="" />
    </div>
  );
};

export default (props) => {
  const { t } = useContext(AppContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <ContainerBlock>
      <CardBlock>
        <TitleCardBlock>
          <Fonts
            text={t(['freeWifi', 'title'])}
            fontSize={18}
            fontWeight="bold"
            color={theme.cardTextColorMenu}
          />
          <ImageTitleCardBlock style={{ height: 37 }} src={wifiIcon} />
        </TitleCardBlock>

        <SliderBlock id="SliderBlock">
          <Slider {...settings}>
            {_.map(_.range(3), (number) => (
              <SliderContentBlock>
                <img src={t(['freeWifiContent', number, 'image'])} alt="" />
                <br />
                <TextBlock>
                  <Fonts
                    text={t(['freeWifiContent', number, 'title'])}
                    fontSize={18}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                </TextBlock>
                <TextBlock>
                  <Fonts
                    text={t(['freeWifiContent', number, 'subTitle'])}
                    fontSize={12}
                    fontWeight="bold"
                    color={theme.cardTextColorMenu}
                  />
                </TextBlock>
              </SliderContentBlock>
            ))}
          </Slider>
        </SliderBlock>
      </CardBlock>
    </ContainerBlock>
  );
};

const SliderBlock = styled.div`
  width: calc(100% - 50px);
  height: calc(100% - 148px);
  /* width: 198px; */
  margin: 50px auto;
  @media screen and (max-width: 320px) {
    margin: 10px auto 20px;
    height: calc(100% - 68px);
  }
`;

const SliderContentBlock = styled.div`
  width: 100%;
  display: flex !important;
  flex-flow: column;
  padding-bottom: 26px;
  > img {
    width: calc(100% - 42px);
    height: 288px;
    margin: auto;
    object-fit: contain;

    @media screen and (max-width: 320px) {
      width: calc(100% - 22px);
    }
  }
`;

const TextBlock = styled.p`
  text-align: center;
  margin-bottom: 0;
`;
