import posed from 'react-pose';
import styled from 'styled-components';

const CardBlockAnimate = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});
export const CardBlock = styled(CardBlockAnimate)`
  margin-top: 16px;
  width: calc(100% - 70px);
  /* height: calc(100% - 45px); */
  border-radius: 16px;
  /* box-shadow: inset 0 4px 0 0 #fff; */
  background: ${(props) => props.theme.cardBg};
  margin: auto;
  padding: 15px 8px;
  @media screen and (max-width: 320px) {
    width: calc(100% - 32px);
    margin-top: 8px;
  }
`;

export const TitleCardBlock = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 14px;
`;

export const ImageTitleCardBlock = styled.img`
  max-height: 100%;
`;
