import styled from 'styled-components';
import posed from 'react-pose';

const ModalBlockAnimate = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

export const ModalBlock = styled(ModalBlockAnimate)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modalfilm = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
`;

export const ContentAnimate = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
});

export const ModalContent = styled(ContentAnimate)`
  width: 280px;
  border-radius: 8px;
  box-shadow: inset 0 4px 0 0 #fff;
  background-image: radial-gradient(circle at 50% 0%, #fff, #e3eaed 37%);
  z-index: 1;
  padding: 35px 0;
`;

export const ModalIcontBlock = styled.div`
  width: 1px;
  height: 1px;
  margin: auto;
  position: relative;
`;

export const IconImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const ModalTextBlock = styled.div`
  text-align: center;
  padding: 16px 16px 14px;
  width: 100%;
`;

export const ModalButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
