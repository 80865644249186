import React, { useContext } from 'react';
import styled from 'styled-components';
import { Badge, Icon } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import size from 'lodash/size';
import includes from 'lodash/includes';

import { ButtonOnPressAnimate, Flex } from '../../style/global';

import flagCN from '../../asset/all/034-china@3x.png';
import flagEN from '../../asset/all/260-united-kingdom@3x.png';
import homeIcon from '../../asset/all/home.svg';
import { AppContext } from '../../context/app';
import Fonts from '../Fonts';
import IconHeart from '../IconHeart';

export default () => {
  const {
    t, changeUrl, changeLangTo, wishlistData,
  } = useContext(AppContext);
  const { lang, pageName } = useParams();
  const history = useHistory();
  const handleClickWishlist = () => {
    history.push(`/${lang}/coupon/mywishlist`);
  };
  return (
    <HeaderContainer>
      <Flex>
        <ButtonBlock onClick={changeUrl('')}>
          <HomeImage src={homeIcon} />
        </ButtonBlock>
        <ButtonBlock onClick={changeLangTo('en')}>
          <LangImage src={flagEN} />
        </ButtonBlock>
        <ButtonBlock onClick={changeLangTo('cn')}>
          <LangImage src={flagCN} />
        </ButtonBlock>
      </Flex>
      {(pageName === 'coupon' || pageName === 'detail') && (
      <WishlistBlock onClick={handleClickWishlist}>
        <Fonts
          text={`${t(['wishlist'])}`}
          fontSize={12}
          fontWeight="bold"
          color="#fff"
        />
        <Badge
          count={size(wishlistData)}
          style={{
            backgroundColor: '#333',
            color: '#ff',
            fontSize: 8,
            boxShadow: '0 0 0 1px #333 inset',
          }}
        >
          {includes(process.env.REACT_APP_LOCATION, 'terminal') ? (
            <Icon
              color="#f50"
              type="heart"
              theme="filled"
              style={{
                color: '#d82759',
                fontSize: 28,
                marginLeft: 8,
              }}
            />
          ) : (
            <IconHeart
              style={{
                color: '#d82759',
                fontSize: 28,
                marginLeft: 8,
              }}
            />
          )}
        </Badge>
      </WishlistBlock>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  padding: 15px 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonBlock = styled(ButtonOnPressAnimate)`
  width: 40px;
  height: 40px;
  background: ${(props) => props.theme.headButtonBackgroundColor};
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const HomeImage = styled.img`
  width: 24px;
  height: 24px;
`;
const LangImage = styled.img`
  width: 20px;
  height: 14px;
`;

const WishlistBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
