import React, { createContext, useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import _ from 'lodash';
import { Spin } from 'antd';
import ls from 'local-storage';
import langData from '../../asset/langData';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { lang } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const [homeBanner, setHomeBanner] = useState([]);
  const [snack, setSnack] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  const [loading, setLoading] = useState(true);

  const [appWidth, setAppWidth] = useState(true);
  const [appHeight, setAppHeight] = useState(true);

  const [wishlistData, setWishlistData] = useState([]);

  const changeLangTo = (toLang) => () => {
    history.push(_.replace(pathname, `/${lang}`, `/${toLang}`));
  };

  const changeUrl = (toUrl) => () => {
    history.push(`/${lang}${toUrl}`);
  };

  const t = (key, data) => {
    const getData = data || langData;
    return (
      _.get(getData, _.flatten([key, lang]))
      || _.get(getData, _.flatten([key, 'en']))
    );
  };

  const fetchData = async () => {
    Axios.get(`${process.env.REACT_APP_API_URL}api/v1/coupon/categories?published=true`)
      .then((response) => {
        setCategories(_.get(response, ['data', 'data']));
      })
      .catch(() => {
        // console.log(error);
      });

    Axios.get(`${process.env.REACT_APP_API_URL}api/v1/coupons?published=true`)
      .then((response) => {
        const result = _.chain(_.get(response, ['data', 'data'])).value();

        setCoupons(result);
      })
      .catch(() => {
        // console.log(error);
      });

    await Axios.get(`${process.env.REACT_APP_API_URL}api/v1/banners?published=true`)
      .then((response) => {
        setHomeBanner(
          _.map(_.get(response, ['data', 'data']), (o) => _.get(o, ['image', 'full_file_path'])),
        );
      })
      .catch((error) => {
        // console.log(error);
      });

    await Axios.get(`${process.env.REACT_APP_API_URL}api/v1/snacks?published=true`)
      .then((response) => {
        const result = _.get(response, ['data', 'data'], []);
        setSnack(result);
      })
      .catch((error) => {
        // console.log(error);
      });

    await Axios.get(`${process.env.REACT_APP_API_URL}api/v1/locations`)
      .then((response) => {
        const result = _.get(response, ['data', 'data'], []);
        setLocations(result);
      })
      .catch((error) => {
        // console.log(error);
      });

    setLoading(false);
  };

  const handleClickWishlist = (id) => () => {
    const temp = ls.get(`wishlist-${process.env.REACT_APP_LOCATION_LS}`) || [];
    const token = ls.get(`token-${process.env.REACT_APP_LOCATION_LS}`);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (_.includes(temp, id)) {
      _.remove(temp, (o) => o === id);
      Axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/coupons/${id}/wishlist`,
        config,
      ).then(() => {});
    } else {
      temp.push(id);
      Axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/coupons/${id}/wishlist`,
        {},
        config,
      ).then(() => {});
    }
    ls.set(`wishlist-${process.env.REACT_APP_LOCATION_LS}`, [...temp]);
    setWishlistData([...temp]);
  };

  useEffect(() => {
    fetchData();
    const rootWidth = document.getElementById('root').offsetWidth;
    const rootHeight = document.getElementById('root').offsetHeight;
    setAppWidth(rootWidth);
    setAppHeight(rootHeight);

    setWishlistData(ls.get(`wishlist-${process.env.REACT_APP_LOCATION_LS}`));
  }, []);

  return (
    <AppContext.Provider
      value={{
        currentLang: lang,
        homeBanner,
        snack,
        t,
        changeUrl,
        appWidth,
        appHeight,
        setLoading,
        changeLangTo,
        coupons,
        categories,
        wishlistData,
        setWishlistData,
        handleClickWishlist,
        locations,
      }}
    >
      <Spin spinning={loading}>{children}</Spin>
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
