import React, { useContext, useState } from 'react';
import { Row, Col } from 'antd';
import Slider from 'react-slick';
import _ from 'lodash';
import styled from 'styled-components';

import { ContainerBlock } from '../style/global';
import Fonts from '../components/Fonts';
import theme from '../theme';
import { AppContext } from '../context/app';
import Button from '../components/Button';
import StaffModal from '../components/StaffModal';
import PasscodeModal from '../components/PasscodeModal';
import SuccessModal from '../components/SuccessModal';
import FailModal from '../components/FailModal';

export default (props) => {
  const [activeStaff, setActiveStaff] = useState(false);
  const [activePasscode, setActivePasscode] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [messageFail, setMessageFail] = useState('');
  const [selectSnack, setSelectSnack] = useState();

  const { t, snack } = useContext(AppContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handlShowPasscode = () => {
    setActivePasscode(true);
  };
  const handlHidePasscode = () => {
    setActivePasscode(false);
  };

  const handlShowStaff = (snackId) => {
    setSelectSnack(snackId);
    setActiveStaff(true);
  };
  const handlHideStaff = () => {
    setActiveStaff(false);
  };

  const handlShowSuccessModal = () => {
    setActiveSuccess(true);
  };
  const handlHideSuccessModal = () => {
    handlHidePasscode();
    setActiveSuccess(false);
  };

  const handlShowFailModal = (message) => {
    setMessageFail(message);
  };
  const handlHideFailModal = () => {
    setMessageFail('');
  };
  if (_.size(snack) <= 0) {
    return <></>;
  }

  return (
    <ContainerBlock>
      <Slider {...settings}>
        {_.map(snack, (data) => (
          <SliderContentBlock>
            <div>
              <TextBlock style={{ lineHeight: '14px' }}>
                <Fonts
                  text={t(['information'])}
                  fontSize={14}
                  fontWeight="bold"
                  color={theme.textHeadColor}
                />
              </TextBlock>
              <TextBlock style={{ lineHeight: '14px' }}>
                <Fonts
                  text={t(['detailPage', 'location'])}
                  fontSize={12}
                  fontWeight="bold"
                  color={theme.textHeadColor}
                />
                {' '}
                <Fonts
                  text={data.location}
                  fontSize={12}
                  color={theme.textHeadColor}
                />
              </TextBlock>
              <TextBlock style={{ lineHeight: '14px' }}>
                <Fonts
                  text={t(['detailPage', 'time'])}
                  fontSize={12}
                  fontWeight="bold"
                  color={theme.textHeadColor}
                />
                {' '}
                <Fonts
                  text={data.time}
                  fontSize={12}
                  color={theme.textHeadColor}
                />
              </TextBlock>
              <TextBlock style={{ lineHeight: '14px' }}>
                <Fonts
                  text={t(['detailPage', 'phone'])}
                  fontSize={12}
                  fontWeight="bold"
                  color={theme.textHeadColor}
                />
                {' '}
                <Fonts
                  text={data.phone}
                  fontSize={12}
                  color={theme.textHeadColor}
                />
              </TextBlock>
            </div>
            <ContentBlock>
              <ImageSlider
                style={{
                  backgroundImage: `url(${_.get(
                    data,
                    ['image', 'full_file_path'],
                    '',
                  )})`,
                }}
              />
              <ContentHeadBlock />

              <TextBlock>
                <Fonts
                  text={t(['name'], data)}
                  fontSize={18}
                  fontWeight="bold"
                  color={theme.cardTextColorMenu}
                />
              </TextBlock>
              <TextBlock>
                <Fonts
                  text={t(['description'], data)}
                  fontSize={14}
                  fontWeight="bold"
                  color={theme.cardTextColorMenu}
                />
              </TextBlock>
              <TextBlock>
                <Fonts
                  text={t(['remark'], data) && `* ${t(['remark'], data)}`}
                  fontSize={12}
                  color={theme.cardTextColorMenu}
                />
              </TextBlock>
              <Row type="flex" justify="center">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      handlShowStaff(_.get(data, 'id'));
                    }}
                  >
                    <Fonts
                      text={t(['detailPage', 'welcomeSnack', 'getSnack'])}
                      fontSize={12}
                      color="#fff"
                    />
                  </Button>
                </Col>
              </Row>
            </ContentBlock>
          </SliderContentBlock>
        ))}
      </Slider>
      <StaffModal
        key="StaffModal"
        active={activeStaff}
        handlHide={handlHideStaff}
        handlShowPasscode={handlShowPasscode}
      />
      <PasscodeModal
        key="PasscodeModal"
        snackId={selectSnack}
        active={activePasscode}
        handlHide={handlHidePasscode}
        handlShowSuccessModal={handlShowSuccessModal}
        handlShowFailModal={handlShowFailModal}
      />
      <SuccessModal active={activeSuccess} handlHide={handlHideSuccessModal} />
      <FailModal message={messageFail} handlHide={handlHideFailModal} />
    </ContainerBlock>
  );
};

const SliderContentBlock = styled.div`
  padding-top: 16px;
  width: calc(100% - 70px);
  min-height: 423px;
  padding: 0 35px 26px;
  display: flex !important;
  flex-direction: column;
  @media screen and (max-width: 320px) {
    padding: 0 16px 26px;
    padding-top: 8px;
    width: calc(100% - 32px);
  }
`;

const TextBlock = styled.p`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0;
`;

const ContentBlock = styled.div`
  margin-top: 24px;
  width: 100%;
  border-radius: 16px;
  box-shadow: inset 0 4px 0 0 #fff;
  background-image: radial-gradient(circle at 50% 0%, #fff, #e3eaed 47%);
  overflow: hidden;
`;

const ImageSlider = styled.div`
  width: 100%;
  padding-bottom: calc(100% * 212 / 308);
  background-size: cover;
  background-position: top center;
`;

const ContentHeadBlock = styled.div`
  padding: 20px 20px 0;

  @media screen and (max-width: 320px) {
    padding: 0;
  }
`;
