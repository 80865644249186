import React from 'react';
import { Icon } from 'antd';

const HeartSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="prefix__b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#EE4F92" />
        <stop offset="100%" stopColor="#D82759" />
      </linearGradient>
      <filter
        id="prefix__a"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      filter="url(#prefix__a)"
      transform="translate(0 -1)"
    >
      <path d="M0 0H24V24H0z" />
      <path
        fill="url(#prefix__b)"
        d="M13.825 1.76c-.674.344-1.294.8-1.825 1.341-.531-.541-1.15-.997-1.825-1.34C9.185 1.255 8.115 1 6.997 1 3.139 1 0 4.13 0 7.976c0 2.725 1.443 5.619 4.29 8.602 2.376 2.49 5.285 4.566 7.307 5.869l.403.26.403-.26c2.022-1.303 4.931-3.38 7.308-5.87C22.557 13.596 24 10.702 24 7.978 24 4.13 20.861 1 17.003 1c-1.119 0-2.188.256-3.178.76z"
      />
    </g>
  </svg>
);

export default (props) => <Icon component={HeartSvg} {...props} />;
