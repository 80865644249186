import React from 'react';

import { HashRouter as Router, Route, Redirect } from 'react-router-dom';

import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Routes from './route';
import { GlobalStyle } from './style/global';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <Route exact path="/">
          <Redirect
            to={{
              pathname: '/en',
            }}
          />
        </Route>
        <Route path="/:lang/:pageName?">
          <Routes />
        </Route>
        {/* <Route path="*">
          <Redirect
            to={{
              pathname: '/en',
            }}
          />

        </Route> */}
      </Router>
    </div>
  );
}

export default App;
