import React, { useContext, useState, useEffect } from 'react';
import { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Axios from 'axios';
import ls from 'local-storage';
import moment from 'moment';
import {
  ModalBlock,
  Modalfilm,
  ModalButtonBlock,
  ContentAnimate,
} from '../../style/ModalStyle';

import Button from '../Button';
import Fonts from '../Fonts';
import { AppContext } from '../../context/app';

import icon from '../../asset/all/snack.png';
import {
  TitleCardBlock,
  ImageTitleCardBlock,
} from '../../style/CardStyle';
import theme from '../../theme';

export default ({
  active,
  handlHide,
  handlShowSuccessModal,
  handlShowFailModal,
  snackId,
}) => {
  const { t, setLoading } = useContext(AppContext);

  const [pass, setPass] = useState('');

  const handleClickNum = (num) => () => {
    const newStringPass = `${pass}${num}`;
    setPass(newStringPass);
  };

  const handleClearNum = () => {
    setPass('');
  };
  const renderPin = () => (
    <>
      {_.map(_.range(1, 4), (number) => {
        const tempNum = number * 3 + 1;
        return (
          <Row type="flex" justify="center">
            {_.map(_.range(tempNum - 3, tempNum), (num) => (
              <Col>
                <Pin onClick={handleClickNum(num)}>
                  <Fonts
                    text={num}
                    fontSize={24}
                    fontWeight="bold"
                    color="#fff"
                  />
                </Pin>
              </Col>
            ))}
          </Row>
        );
      })}
      <Row type="flex" justify="center">
        <Col>
          <Pin invisible />
        </Col>
        <Col>
          <Pin onClick={handleClickNum(0)}>
            <Fonts text={0} fontSize={24} fontWeight="bold" color="#fff" />
          </Pin>
        </Col>
        <Col>
          <Pin clear onClick={handleClearNum}>
            <Fonts text="X" fontSize={24} fontWeight="bold" color="#fff" />
          </Pin>
        </Col>
      </Row>
    </>
  );

  const passLength = _.size(pass);
  useEffect(() => {
    if (_.size(pass) === 4) {
      setLoading(true);

      const token = ls.get(`token-${process.env.REACT_APP_LOCATION_LS}`);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // const snackId = 1;
      Axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/snacks/${snackId}/get`,
        {
          passcode: pass,
        },
        config,
      )
        .then((response) => {
          const status = _.get(response, ['data', 'status']);
          if (status !== 'success') {
            let errorMessage = _.get(response, ['data', 'message']);
            if (errorMessage === 'Invalid Passcode') {
              errorMessage = t(['detailPage', 'welcomeSnack', 'popErrorPass']);
            }
            handlShowFailModal(errorMessage);
          } else {
            ls.set(
              `snack-${process.env.REACT_APP_LOCATION}`,
              moment().format('YYYY-MM-DD'),
            );
            handlShowSuccessModal();
          }
        })
        .finally(() => {
          setLoading(false);
          setPass('');
        });
    }
  }, [pass, snackId]);
  return (
    <PoseGroup>
      {active && (
        <ModalBlock key="ModalBlock">
          <Modalfilm
            onClick={() => {
              handlHide();
            }}
          />
          <PasscodeBlock>
            <TitleCardBlock>
              <Fonts
                text={t(['detailPage', 'welcomeSnack', 'getSnack'])}
                fontSize={18}
                fontWeight="bold"
                color={theme.cardTextColorMenu}
              />
              <ImageTitleCardBlock style={{ height: 48 }} src={icon} />
            </TitleCardBlock>
            <PasscodePinBlock>
              <CodeBlock>
                {_.map(_.range(1, 5), (o) => (
                  <CodeCircle active={o <= passLength} />
                ))}
              </CodeBlock>
              <Row>{renderPin()}</Row>
              <ModalButtonBlock>
                <Button
                  onClick={() => {
                    handlHide();
                  }}
                >
                  <Fonts text={t(['cancel'])} fontSize={12} color="#fff" />
                </Button>
              </ModalButtonBlock>
            </PasscodePinBlock>
          </PasscodeBlock>
        </ModalBlock>
      )}
    </PoseGroup>
  );
};

const PasscodeBlock = styled(ContentAnimate)`
  width: calc(100% - 70px);
  height: calc(100% - 45px);
  border-radius: 16px;
  box-shadow: inset 0 4px 0 0 #fff;
  background: ${(props) => props.theme.cardBg};
  margin: auto;
  padding: 15px 8px;
  @media screen and (max-width: 320px) {
    width: calc(100% - 32px);
  }
`;

const PasscodePinBlock = styled.div`
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CodeBlock = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 42px 0 0;

  @media screen and (max-width: 320px) {
    margin: 8px;
  }
`;

const CodeCircle = styled.div`
  width: 24px;
  height: 24px;
  border: solid 2px #333333;
  background-color: ${(props) => (props.active ? '#333333' : 'transparent')};
  margin: 0 12px;
  border-radius: 50%;
`;

const Pin = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.5);
  background-color: ${(props) => (props.clear ? '#333333' : '#d82759')};

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 15px;

  opacity: ${(props) => (props.invisible ? 0.01 : 1)};

  @media screen and (max-width: 320px) {
    margin: 8px 15px;
  }
`;
